import App from './App.js';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { detect } from 'detect-browser';

window.__SITE_TITLE_BASE = `Webcams.Click`;

const appRoot = document.querySelector('#app');
const root = ReactDOM.createRoot(appRoot);
root.render(<App/>);

const browser = detect();

/*
 * Safari <=14: Aspect ratio fix
 */
if (browser.name == 'safari') {
    const baseVersion = parseInt(browser.version.split('.')[0]);
    if (baseVersion < 15) {
        
        const st = document.createElement('style');
        st.innerHTML = `
        :root {
            --thumb-height-safari: 120px;
        }
        .cbs-cam-thumb-container {
            min-height: var(--thumb-height-safari);
        }`;
        document.head.appendChild(st);
        
        const root = document.querySelector(':root');
        
        const safariResize = () => {
            const t = document.querySelector('.cbs-cam-thumb-container');
            if (!t) return;
            const w = t.offsetWidth;
            const h = w*(3/5);
            root.style.setProperty('--thumb-height-safari', `${h}px`);
        }
        window.addEventListener('resize', safariResize);
        setTimeout(() => safariResize(), 100);
        
    }
}
