import { useState } from "react";
import { getAPI } from "../utils/Utils";

export default function PopularTags(props) {
    
    const [tags, setTags] = useState(null);
    const [errorText, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    
    if (!isLoading && !errorText && !tags) {
        getAPI(`/api/popularTags`)
            .then(sd => {
                if (sd.error) throw new Error(sd.content ?? sd.error);
                setTags(sd.data);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
            })
    }
    
    if (isLoading || !tags) {
        return <div className='cbs-tags'>Just a sec...</div>;
    }
    
    return <div className='cbs-tags'>
        {tags.map(([tag, users], el) => <div className='cbs-tag big'
            key={el}
            onClick={e => {
                e.preventDefault();
                window.location.href = `#/tag/${encodeURIComponent(tag)}`;
            }}
            >
            #{tag}
        </div>)}
    </div>;
    
}