import { useState } from "react";

export default function Check(_props) {
    
    const props = {..._props};
    const isSwitch = props.isSwitch ?? false;
    if (props.isSwitch) {
        delete props.isSwitch;
    }
    
    const [_id, _setId] = useState(null);
    if (!_id) _setId(Date.now() + '_' + Math.floor(Math.random()*100000).toString(36));
    
    return <div className={`form-check${isSwitch ? ' form-switch' : ''}`}>
        <input className='form-check-input' type='checkbox' id={props.id ?? _id} {...props}/>
        {props.label
            ? <label className='form-check-label' htmlFor={props.id ?? _id}>{props.label}</label>
            : ''}
    </div>;
    
}