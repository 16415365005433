export default function RemixIcon(props) {
    
    const iconClass = props.icon ?? 'ri-admin-line';
    const classes = [];
    
    classes.push(iconClass.indexOf('ri-') != 0 ? 'ri-' + iconClass : iconClass);
    classes.push('ri-icon');
    classes.push(...(props.className ?? '').split(' '));
    
    return <i
        className={classes.filter(c => c != '').join(' ')}
        />;
        
}