import React, { Suspense, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { BrowserRouter, Link, Route, Routes, useParams } from "react-router-dom";
import CamScroller from "./components/CamScroller";
import PopularTags from "./components/PopularTags";
import RemixIcon from "./utils/RemixIcon";
import Front404 from "./views/Front404";

const FrontAbout = React.lazy(() => import('./views/FrontAbout'));
const FrontStats = React.lazy(() => import('./views/FrontStats'));
const FrontExplore = React.lazy(() => import('./views/FrontExplore'));
const FrontCountry = React.lazy(() => import('./views/FrontCountry'));

function CamSearch(props) {
    
    const [search, setSearch] = useState('');
    const [lastHash, setLastHash] = useState('');
    
    const hashUpdate = () => {
        const hash = decodeURIComponent((window.location.hash ?? ' ').substring(1));
        if (hash != lastHash) {
            const pts = hash.split('/').slice(1);
            if (pts.length > 1) {
                if (pts[0] == 'tag') {
                    const _s = '#' + pts[1];
                    setSearch(_s);
                    props.setUsedSearch(_s);
                } else if (pts[0] == 'search') {
                    if (search != pts[1]) {
                        setSearch(pts[1]);
                        props.setUsedSearch(pts[1]);
                    }
                }
                setLastHash(hash);
            }
        }
    }
    
    useEffect(() => {
        const onHashChange = () => hashUpdate();
        hashUpdate();
        window.addEventListener('hashchange', onHashChange);
        return () => {
            window.removeEventListener('hashchange', onHashChange);
        };
    }, []);
    
    return <div className='input-group'>
        <input
            type='text'
            className='form-control'
            placeholder='Search for anything...'
            aria-describedby='__search'
            value={search}
            onChange={e => setSearch(e.target.value)}
            autoFocus={true}
            spellCheck={false}
            autoComplete='off'
            onKeyDown={e => {
                if (e.key == 'Enter') {
                    props.setUsedSearch(search);
                }
            }}
            />
        <button className='btn btn-cbs' type='button' id='__search'><RemixIcon icon='search-2-line' className='mx-2'/></button>
    </div>;
    
}

function TopBar(props) {
    return <div key='top' className='sticky-top cbs-top'>
        <div className='container p-2'>
            <div className='row justify-content-center align-items-center'>
                <div className='col-12 col-lg-3 align-self-center cbs-logo mb-2 mb-sm-0'>
                </div>
                <div className='col-2 mb-2 d-lg-none mb-sm-0'>
                    <button className='btn btn-cbs' type='button' data-bs-toggle='collapse' data-bs-target='#__menu'>
                        <RemixIcon icon='menu-line' className='px-1'/>
                    </button>
                </div>
                <div className='col-10 col-sm-10 col-lg-6 align-self-center mb-2 mb-sm-0'>
                    {props.showSearch
                        ? <CamSearch {...props}/>
                        : ''}
                </div>
                <div className='col-12 col-lg-3 text-center text-lg-end align-self-center'>
                    <div className='d-none d-lg-block'>
                        <Link to='/' className='cbs-top-icon me-2'><RemixIcon icon='home-2-line'/></Link>
                        <a href='/about' className='cbs-top-icon'><RemixIcon icon='question-line'/></a>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

function Sidebar(props) {
    
    const path = window.location.pathname;
    const pathParts = path.split('/').slice(1);
    const basePath = pathParts[0] ?? '';
    
    const getActiveClass = (p) => {
        return `cbs-side-item${p == basePath ? ' active' : ''}`;
    }
    
    return <>
        <div>
            <Link to='/' className='cbs-side-link'>
                <div className={getActiveClass('')}>
                    <RemixIcon icon='search-eye-line'/> Search webcams
                </div>
            </Link>
            <Link to='/explore' className='cbs-side-link'>
                <div className={getActiveClass('explore')}>
                    <RemixIcon icon='share-line'/> Explore categories
                </div>
            </Link>
            <Link to='/country' className='cbs-side-link'>
                <div className={getActiveClass('country')}>
                    <RemixIcon icon='earth-line'/> Explore countries
                </div>
            </Link>
            <a href='/stats' className='cbs-side-link'>
                <div className='cbs-side-item'>
                    <RemixIcon icon='bar-chart-2-fill'/> View stats
                </div>
            </a>
            <Link to='/about' className='cbs-side-link'>
                <div className='cbs-side-item'>
                    <RemixIcon icon='questionnaire-line'/> About webcams
                </div>
            </Link>
            <a href='https://chaturbate.com/in/?tour=3Mc9&campaign=Q7BIE&track=default&redirect_to_room=-welcomepage-'
                target='_blank'
                className='cbs-side-link'>
                <div className='cbs-side-item'>
                    <RemixIcon icon='user-add-line'/> Register to Chaturbate
                </div>
            </a>
            <a href='https://chaturbate.com/in/?tour=5bpG&campaign=Q7BIE&track=default'
                target='_blank'
                className='cbs-side-link'>
                <div className='cbs-side-item'>
                    <RemixIcon icon='webcam-line'/> Become a model
                </div>
            </a>
        </div>

        <div className='ps-2 mt-2'>
            Popular tags
            <PopularTags/>
        </div>

        <div className='ps-2 mt-2'>
            <small style={{ fontSize: '9pt' }}>
                <div className='d-block'>
                    <RemixIcon icon='twitter-line'/> <a href='https://twitter.com/WebcamsClick' target='_blank'>Follow us on Twitter!</a>
                </div>
                <div className='d-block'>
                    <RemixIcon icon='information-line'/> Thumbnails and content from <a href='https://webcams.click/cams/home' target='_blank'>Chaturbate</a>
                </div>
            </small>
        </div>
    </>;
    
}

function Front(props) {
    
    const [isFirst, setFirst] = useState(true);
    let initialSearch = '';
    (() => {
        const hash = decodeURIComponent((window.location.hash ?? ' ').substring(1));
        const pts = hash.split('/').slice(1);
        if (isFirst && pts.length > 1) {
            if (pts[0] == 'tag') {
                const _s = '#' + pts[1];
                initialSearch = _s;
            } else if (pts[0] == 'search') {
                initialSearch = pts[1];
            }
            setFirst(false);
        }
    })();
    
    const [usedSearch, setUsedSearchState] = useState(initialSearch);
    const setUsedSearch = val => {
        const hashVal = `#/search/${encodeURIComponent(val)}`;
        if (window.location.hash != hashVal) {
            window.location.hash = hashVal;
        }
        setUsedSearchState(val);
    }
    
    return <>
        <TopBar showSearch={true} setUsedSearch={setUsedSearch}/>
        
        <div key='content' className='container-fluid'>
            <div className='row'>
                <div className='col-12 col-sm-12 col-md-4 col-lg-3 col-xl-2 cbs-side-nav px-0 pb-3 collapse d-lg-block' id='__menu'>
                    <Sidebar/>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-9 col-xl-10 mt-2'>
                    <CamScroller search={usedSearch}/>
                </div>
            </div>
        </div>
    </>;
    
}

export default function App() {
    
    return <>
        
        <Suspense fallback={<div className='p-4 m-4 text-center'>Just a sec...</div>}>
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Front/>}/>
                    <Route path='/about' element={<FrontAbout topBar={TopBar}/>}/>
                    <Route path='/stats' element={<FrontStats topBar={TopBar}/>}/>
                    <Route path='/explore' element={<FrontExplore topBar={TopBar} sideBar={Sidebar}/>}/>
                    <Route path='/explore/:id' element={<FrontExplore topBar={TopBar} sideBar={Sidebar}/>}/>
                    
                    <Route path='/country' element={<FrontCountry topBar={TopBar} sideBar={Sidebar}/>}/>
                    <Route path='/country/:name' element={<FrontCountry topBar={TopBar} sideBar={Sidebar}/>}/>
                    
                    <Route path='*' element={<Front404 topBar={TopBar}/>}/> 
                </Routes>
            </BrowserRouter>
        </Suspense>
        
    </>;
    
}