import copy from "copy-to-clipboard";
import { useState } from "react";
import RemixIcon from "./RemixIcon";


export const genderColors = {
    f: '#ff8af9',
    m: '#8a9eff',
    t: '#afff8a',
    c: '#ffce8a'
};

export function GenderIcon(props) {
    const gender = props.gender ?? '';
    return <span 
        className={'d-inline-block me-1' + (props.noAlign ? '' : ' align-middle')}
        style={{ color: genderColors[gender] ?? '#ffffff' }}
        >
        <RemixIcon icon={gender == 'c' ? 'group-line' : 'user-line'}/>
    </span>;
}

export default function CamThumb(props) {
    const camData = props.data;
    return <a href={`https://webcams.click/r/${encodeURIComponent(camData.username)}`}
        className='cbs-cam-link'
        onClick={e => {
            if (e.altKey) {
                e.preventDefault();
                window.open(`https://chaturbate.com/${encodeURIComponent(camData.username)}`);
            }
        }}
        >
        <div className='cbs-cam'>
            <div className='cbs-cam-thumb-container'>
                <img src={camData.image_url} className='cbs-cam-thumb' loading='lazy'/>
                {camData.age && camData.age != ''
                    ? <span className='cbs-cam-age'>{camData.age}</span>
                    : ''}
                {camData.is_hd
                    ? <span className='cbs-cam-hd'>HD</span>
                    : ''}
                {camData.is_new
                    ? <span className='cbs-cam-new'>NEW</span>
                    : ''}
                {camData.current_show != 'public'
                    ? <span className='cbs-cam-show'>{camData.current_show}</span>
                    : ''}
            </div>
            <div className='cbs-cam-username'
                onClick={e => {
                    e.preventDefault();
                    if (e.metaKey || e.ctrlKey) {
                        copy(camData.username);
                        toastAlert(`Username copied to clipboard!`);
                    }
                }}
                >
                <GenderIcon gender={camData.gender}/>
                {camData.username}
            </div>
            <div className='cbs-cam-detail'>{camData.room_subject}</div>
            <div className='cbs-cam-detail'>
                <RemixIcon icon='eye-line'/> {camData.num_users.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} viewers
            </div>
            <div className='cbs-cam-detail'>
                <RemixIcon icon='map-pin-2-line'/> {camData.location}
            </div>
            {props.details
                ? <>
                    <div className='cbs-cam-detail'>
                        <RemixIcon icon='star-line'/> {camData.num_followers.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} followers
                    </div>
                    <div className='cbs-cam-detail'>
                        <RemixIcon icon='time-line'/> {(Math.round(camData.seconds_online/60/60 * 10)/10).toFixed(1)} hours
                    </div>
                    {camData.birthday
                        ? <div className='cbs-cam-detail'>
                            <RemixIcon icon='cake-line'/> Birthday: {(new Date(camData.birthday)).toLocaleDateString('en-US', {
                                month: 'long',
                                day: 'numeric'
                            }).split(' ').reverse().join(' ')}
                        </div>
                        : ''}
                </>
                : ''}
            <div className='cbs-cam-detail mt-1'>{camData.tags.map((tag, i) => <span key={i} className='cbs-tag'>#{tag}</span>)}</div>
        </div>
    </a>;
}