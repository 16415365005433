import { useEffect, useState } from "react";

export default function Front404(props) {
    
    const TopBar = props.topBar;
    
    useEffect(() => {
        document.title = [
            window.__SITE_TITLE_BASE,
            `404 Not Found`
        ].join(' - ');
    }, []);
    
    return <>
        
        <TopBar/>
        
        <div className='container'>
            <div className='row justify-content-center mt-3'>
                <div className='col-12 col-md-10 col-lg-10 col-xl-8'>
                    <div className='cbs-container p-4 text-center'>
                        
                        <h2>Wait, how'd you get here?</h2>
                        <p>This page doesn't exist.</p>
                        
                        <hr/>
                        
                        <p><a href='/'>&laquo; Go back to home</a></p>
                        
                    </div>
                </div>
            </div>
        </div>
        
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        
    </>;
    
}