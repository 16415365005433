import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Check from "../utils/Check";
import RemixIcon from "../utils/RemixIcon";
import { getAPI } from "../utils/Utils";

import { useTriggerScrollFix } from '../utils/useTriggerScrollFix.js';
import copy from "copy-to-clipboard";
import CamThumb from "../utils/CamThumb";

const genderColors = {
    f: '#ff8af9',
    m: '#8a9eff',
    t: '#afff8a',
    c: '#ffce8a'
};

function GenderIcon(props) {
    const gender = props.gender ?? '';
    return <span 
        className={'d-inline-block me-1' + (props.noAlign ? '' : ' align-middle')}
        style={{ color: genderColors[gender] ?? '#ffffff' }}
        >
        <RemixIcon icon={gender == 'c' ? 'group-line' : 'user-line'}/>
    </span>;
}

export default function CamScroller(params) {
    
    const [page, setPage] = useState(0);
    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [errorText, setError] = useState(false);
    const [itemsCount, setItemsCount] = useState(9999);
    const [isLoading, setLoading] = useState(false);
    
    const [filter, setFilter] = useState({});
    const [filterGenders, setFilterGenders] = useState({ female: true, male: true, trans: true, couples: true });
    const [filterShows, setFilterShows] = useState({ public: true, private: false, group: false, away: false });
    
    const [currentSearch, setCurrentSearch] = useState(params.search ?? '');
    const searchAge = (filter.age ?? '18,9999').split(',');
    const ageMin = searchAge.length > 1 ? parseInt(searchAge[0]) : 18;
    const ageMax = searchAge != '' && searchAge.length > 1 ? parseInt(searchAge[searchAge.length-1]) : 9999;
    
    const [showDetails, setShowDetails] = useState(false);
    
    const fetchData = async () => {
        
        const searchParams = {
            
            sort: filter.sort ?? ',reverse',
    
            genders: Object.entries(filterGenders).filter(([k,v]) => !!v).map(([k,v]) => k).join(','),
            shows: Object.entries(filterShows).filter(([k,v]) => !!v).map(([k,v]) => k),
            
            ageMin: ageMin,
            ageMax: ageMax,
    
            onlyHD: !!filter.hd,
            onlyNew: !!filter.new,
            //onlyPublic: searchPublic,
            
            search: params.search ?? currentSearch,
            page: page
            
        };
        
        let d = null;
        const toPush = [];
        
        while (d == null || (d.results.length == 0 && !d.ended)) {
            d = await getAPI(`/api/search/${encodeURIComponent(JSON.stringify(searchParams))}`);
            if (d.error) throw new Error(d.content ?? d.error);
            toPush.push(...d.results);
            searchParams.page++;
        }
        
        const _items = [ ...items, ...toPush ];
        const _page = searchParams.page+1;
        
        setHasMore(!d.ended);
        setItems(_items);
        setItemsCount(_items.count);
        setPage(_page);
        
    }
    
    const resetSearch = () => {
        setHasMore(true);
        setPage(0);
        setItems([]);
    }
    
    if (params.search != currentSearch) {
        setCurrentSearch(params.search);
        resetSearch();
    }
    
    if (!isLoading && items.length == 0) {
        setLoading(true);
        fetchData()
            .then(() => setLoading(false))
            .catch(err => console.error(err));
    }
    
    const onFilterGenders = e => {
        setFilterGenders({
            ...filterGenders,
            [e.target.name]: e.target.checked
        });
        resetSearch();
    }
    
    const onFilterShows = e => {
        setFilterShows({
            ...filterShows,
            [e.target.name]: e.target.checked
        });
        resetSearch();
    }
    
    const onFilter = e => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.matches('input[type="checkbox"]') ? e.target.checked : e.target.value
        });
        resetSearch();
    }
    
    useTriggerScrollFix([items.length, page]);
    
    return <div className='row justify-content-center'>
        <div className='col-12 col-md-7 col-lg-9 col-xm-10 order-2 order-md-1'>
            <div>
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchData}
                    hasMore={hasMore}
                    loader={<div className='text-center mb-4' style={{ width: '100%', gridColumn: '1/-1' }}>
                        <div className='spinner-border text-light' role='status'></div>
                        <p>Filtering page {page+1}...</p>
                    </div>}
                    endMessage={<div className='text-center mb-4' style={{ width: '100%', gridColumn: '1/-1' }}>
                        <h1 className='mb-0'><RemixIcon icon='ghost-line'/></h1>
                        <p className='lead'>You reached the end!</p>
                    </div>}
                    className='cbs-cam-grid'
                    initialScrollY={window.innerHeight+100}
                    >
                    {items.map((it, i) => <CamThumb data={it} key={i} details={showDetails}/>)}
                    {[...(' '.repeat(10))].map((_, i) => <p key={-i}>&nbsp;</p>)}
                </InfiniteScroll>
            </div>
        </div>
        <div className='col-12 col-md-4 col-lg-2 order-1 order-md-2'>
            <div>
                <div className='mb-4'>
                    <Check label={<>Show details</>}
                            name='details'
                            checked={showDetails}
                            onChange={e => setShowDetails(e.target.checked)}
                            isSwitch={true}
                            style={{ verticalAlign: 'middle', marginTop: '5px' }}
                            />
                </div>
                <div className='mb-2'>
                    <div className='cbs-small-title'>
                        <RemixIcon icon='equalizer-line'/> Show genders
                    </div>
                    <div className='p-2'>
                        <Check label={<><GenderIcon gender='f'noAlign={true}/>Girls</>}
                            name='female'
                            checked={!!filterGenders.female}
                            onChange={onFilterGenders}/>
                        <Check label={<><GenderIcon gender='m' noAlign={true}/>Guys</>}
                            name='male'
                            checked={!!filterGenders.male}
                            onChange={onFilterGenders}/>
                        <Check label={<><GenderIcon gender='t' noAlign={true}/>Trans</>}
                            name='trans'
                            checked={!!filterGenders.trans}
                            onChange={onFilterGenders}/>
                        <Check label={<><GenderIcon gender='c' noAlign={true}/>Couples</>}
                            name='couples'
                            checked={!!filterGenders.couples}
                            onChange={onFilterGenders}/>
                    </div>
                </div>
                <div className='mb-2'>
                    <div className='cbs-small-title'>
                        <RemixIcon icon='search-eye-line'/> Show ages
                    </div>
                    <div className='p-2'>
                        <select
                            name='age'
                            value={filter.age}
                            onChange={onFilter}
                            className='form-control form-control-sm'
                            >
                            <option value=''>Any age</option>
                            <option value='18,25'>18-25 years</option>
                            <option value='25,30'>25-30 years</option>
                            <option value='30,40'>30-40 years</option>
                            <option value='40,50'>40-50 years</option>
                            <option value='50,'>50+ years</option>
                        </select>
                    </div>
                </div>
                <div className='mb-2'>
                    <div className='cbs-small-title'>
                        <RemixIcon icon='sort-desc'/> Sort by
                    </div>
                    <div className='p-2'>
                        <select
                            name='sort'
                            value={filter.sort}
                            onChange={onFilter}
                            className='form-control form-control-sm'
                            >
                            <option value=',reverse'>None</option>
                            <option value='viewers,reverse'>Viewers (highest first)</option>
                            <option value='viewers'>Viewers (lowest first)</option>
                            <option value='followers,reverse'>Followers (highest first)</option>
                            <option value='followers'>Followers (lowest first)</option>
                            <option value='age,reverse'>Age (highest first)</option>
                            <option value='age'>Age (lowest first)</option>
                            <option value='time,reverse'>Time online (highest first)</option>
                            <option value='time'>Time online (lowest first)</option>
                        </select>
                    </div>
                </div>
                <div className='mb-2'>
                    <div className='cbs-small-title'>
                        <RemixIcon icon='lock-line'/> Show types
                    </div>
                    <div className='p-2'>
                        <Check label='Public'
                            name='public'
                            checked={!!filterShows.public}
                            onChange={onFilterShows}/>
                        <Check label='Private'
                            name='private'
                            checked={!!filterShows.private}
                            onChange={onFilterShows}/>
                        <Check label='Group'
                            name='group'
                            checked={!!filterShows.group}
                            onChange={onFilterShows}/>
                        <Check label='Away'
                            name='away'
                            checked={!!filterShows.away}
                            onChange={onFilterShows}/>
                    </div>
                </div>
                <div className='mb-2'>
                    <div className='cbs-small-title'>
                        <RemixIcon icon='filter-line'/> Other filters
                    </div>
                    <div className='p-2'>
                        <Check label='HD only' name='hd' checked={!!filter.hd} onChange={onFilter}/>
                        <Check label='New only' name='new' checked={!!filter.new} onChange={onFilter}/>
                    </div>
                </div>
            </div>
        </div>
    </div>;
    
}